import React, {useEffect, useState} from 'react';
import TextNumberInput from "../../components/UI/inputs/text-number/TextNumberInput";
import ErrorPost from "../../components/UI/error-post/ErrorPost";
import ButtonPrime from "../../components/UI/buttons/button-prime/ButtonPrime";
import {useLazyGetCartByIdQuery, useMakeOrderMutation} from "../../store/cart/cart.api";
import {useAppSelector} from "../../hooks/redux";
import {JWT_KEY} from "../../config";
import {useNavigate} from "react-router-dom";
import {Cart} from "../../types";
import Modal from "../../components/UI/modals/Modal";
import ButtonSecondary from "../../components/UI/buttons/button-secondary/ButtonSecondary";
import {useLazyGetOneQuery} from "../../store/user/user.api";
import {decodeToken} from "react-jwt";
import {useActions} from "../../hooks/actions";
import styles from './Order.module.scss';
import Section from "../../components/UI/section/Section";

const Order = () => {
    const [description, setDescription] = useState('')
    const [address, setAddress] = useState('')
    const [makeOrder, {isLoading, error, data}] = useMakeOrderMutation()
    const {user} = useAppSelector(state => state.user)
    const navigate = useNavigate()
    const [getCart] = useLazyGetCartByIdQuery()
    const [cart, setCart] = useState() as [Cart, Function]
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [getUser] = useLazyGetOneQuery()
    const {setUserState} = useActions()
    const [phone, setPhone] = useState() as [string | undefined,  Function]
    const {errors} = useAppSelector(state => state.ui)


    useEffect(() => {
        if (!localStorage.getItem(JWT_KEY)) {
            navigate('/authorization')
        }
    }, [])

    useEffect(() => {
        if (user.id) {
            getCart(user.activeCart.id).then(res => res.data && setCart(res.data))
            setPhone(user.phone)
        }
    }, [user])

    const send = () => {

        makeOrder({
            cartId: user.activeCart.id,
            userId: user.id,
            description,
            address,
            ...(!!phone ? {phone} : {}),
            finalPrice: cart.productsInCart.reduce((acc, item) => acc + item.quantity * item.price, 0)
        }).then(res => {
            // @ts-ignore
            if (res.data) {
                setIsModalOpen(true)
                const {id} = decodeToken(localStorage.getItem(JWT_KEY) || '') as { id: number }
                id && getUser(id).then(res => res.data && setUserState(res.data))
            }
        })
    }

    const closeModal = () => {
        setIsModalOpen(false)
        navigate('/')
    }

    useEffect(() => {

    } , [phone])

    return (
        <div>
            <h1 className={["page-title", styles.title].join(' ')}>
                Оформление заказа
            </h1>
            <ErrorPost> Доставка возможна только по Иркутcку</ErrorPost>
            <div className={styles.content}>
                <Section>
                    <TextNumberInput error={phone?.includes('_') && 'Заполните полностью'} onChange={value => setPhone(!!value ? String(value) : null)} required={true} label={"Телефон"} value={phone} type={"tel"}></TextNumberInput>
                </Section>
                <Section>
                    <TextNumberInput rows={1} required={false} label={"Ваши пожелания к заказу"} type={"textarea"}
                                     value={description}
                                     onChange={value => setDescription(String(value))}></TextNumberInput>
                </Section>
                <Section>
                    <TextNumberInput rows={1} required={false} label={"Адрес доставки"} type={"textarea"} value={address}
                                     onChange={value => setAddress(String(value))}></TextNumberInput>
                </Section>

            </div>
            <Section>
                <ButtonPrime isLoading={isLoading} className={!cart || isLoading || !!errors.length ? "disabled" : ''}
                             onClick={() => send()}> Отправить</ButtonPrime>
                {error && <ErrorPost>
                    Не удалось отправить заказ, ошибка на стороне сервера.
                </ErrorPost>}
                {(!!errors.length) && <ErrorPost>Все необходимые поля должны быть заполнены, причем корректно</ErrorPost>}

            </Section>

            <Modal isModalOpen={isModalOpen} setIsModalOpen={value =>closeModal()}>
                <div className={styles.modal}>
                    <p className={styles.modal__text}>
                        Заказ успешно отправлен. В&nbsp;скором
                        времени мы свяжемся с вами.
                    </p>
                    <ButtonSecondary className={styles.modal__button} onClick={() => closeModal()}> Хорошо</ButtonSecondary>
                </div>

            </Modal>
        </div>
    );
};

export default Order;
