import React from 'react';
import Catalog from "../../components/catalog/Catalog";


const CakeCatalog = () => {
    return (
      <Catalog title={"Каталог тортов"} typeId={1}></Catalog>
    );
};

export default CakeCatalog;